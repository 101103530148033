<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <!-- <el-breadcrumb-item v-if="isPrivateLesson || isCounseling">
          <router-link :to="`/private/${routePath}`">
            {{
              $t(
                `pageTitle.${isPrivateLesson ? "privateLessons" : "counseling"}`
              )
            }}
          </router-link>
        </el-breadcrumb-item> -->
        <el-breadcrumb-item>
          <router-link :to="{ name: 'myClasses' }">
            {{ $t("pageTitle.myClasses") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <!-- <router-link
            v-if="isPrivateLesson || isCounseling"
            :to="`/private/${routePath}/${$route.params.courseId}/detail`"
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ lessonTitle }}
          </router-link> -->
          <router-link
            :to="{
              name: 'ClassLessonLogs',
              params: { classId: $route.params.courseId }
            }"
          >
            <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
            {{ lessonTitle }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ `${logType} Log Details` }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-divider />
    <div style="display: flex; align-items: flex-end">
      <h1 style="margin: 0px">{{ `${logType} Log Details` }}</h1>
      <router-link
        v-if="isRoleAdmin() || (isRoleTeacher() && isTeacherInClass)"
        style="font-weight: bold; margin-left: 8px;"
        :to="{
          name: 'privateClassesEditLog',
          params: {
            privateType: routePath,
            id: $route.params.courseId,
            lessonLogId: lessonLogs[0].id
          }
        }"
      >
        (edit)
      </router-link>
    </div>
    <div class="lessonCard">
      <el-row :gutter="20" class="rowGap" type="flex" justify="center">
        <el-col :span="6">
          <div class="alignRight">Class:</div>
        </el-col>
        <el-col v-if="sessionClass" :span="18">
          <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
          {{ lessonTitle }}
        </el-col>
      </el-row>
      <el-row :gutter="20" class="rowGap" type="flex" justify="center">
        <el-col :span="6">
          <div class="alignRight">Student:</div>
        </el-col>
        <el-col :span="18">
          <div
            :key="attendanceStatuse"
            v-for="attendanceStatuse in Object.keys(EnumAttendanceStatuses)"
          >
            <span
              class="attendanceStatusLight"
              v-if="
                filterStudentByAttendance(
                  students,
                  EnumAttendanceStatuses[attendanceStatuse]
                ).length > 0
              "
            >
              <AttendanceStatusLight
                :attendanceStatus="EnumAttendanceStatuses[attendanceStatuse]"
              />
              {{ `${attendanceStatuse}: ` }}
            </span>
            <span
              :key="student.student_user_id"
              v-for="(student, index) in filterStudentByAttendance(students, EnumAttendanceStatuses[attendanceStatuse])"
            >
              <router-link
                :to="{
                  name: 'ProfileForAdmin',
                  params: { id: student.student_user_id }
                }"
              >
                {{ user.displayName(student.student_user.first_name, student.student_user.last_name) }}
              </router-link>
              <span
                v-if="
                  EnumAttendanceStatuses[attendanceStatuse] ===
                    EnumAttendanceStatuses.Excused
                "
              >
                (<span class="absenceExcuse">
                  {{ getAbsenceExcuseByUserId(student.student_user_id) }}
                </span>)
              </span>
              <span v-if="index !== filterStudentByAttendance(students, EnumAttendanceStatuses[attendanceStatuse]).length - 1">, </span>
            </span>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="rowGap" type="flex" justify="center">
        <el-col :span="6">
          <div class="alignRight">Teacher:</div>
        </el-col>
        <el-col :span="18">
          {{ user.displayName(teachingLog.teacher_user.first_name, teachingLog.teacher_user.last_name) }}
        </el-col>
      </el-row>
      <el-row :gutter="20" class="rowGap" type="flex" justify="center">
        <el-col :span="6">
          <div class="alignRight">{{ `${logType} Time:` }}</div>
        </el-col>
        <el-col :span="18">
          {{
            `${teachingLog.started_at} (Taipei time)(${
              teachingLog.full_hour
            } hour${teachingLog.full_hour === 1 ? "" : "s"})`
          }}
        </el-col>
      </el-row>
      <el-row :gutter="20" class="rowGap" type="flex" justify="center">
        <el-col :span="6">
          <div class="alignRight">{{ `Today's ${logType}:` }}</div>
        </el-col>
        <el-col :span="18">
          <div v-html="teachingLog.description" />
        </el-col>
      </el-row>
      <el-row :gutter="20" class="rowGap" type="flex" justify="center">
        <el-col :span="6">
          <div class="alignRight">Homework:</div>
        </el-col>
        <el-col :span="18">
          <div v-html="teachingLog.homework" />
        </el-col>
      </el-row>
      <el-row :gutter="20" class="rowGap" type="flex" justify="center">
        <el-col :span="6">
          <div class="alignRight">Individual Notes:</div>
        </el-col>
        <el-col :span="18">
          <el-row style="margin: 0px">
            <el-col v-if="isTeachingLog" :span="6" style="margin-right: 20px">
              <el-menu :defaultActive="String(currentStudentId)">
                <el-menu-item
                  :index="String(student.student_user_id)"
                  :key="student.student_user_id"
                  v-for="student in students"
                  @click="
                    () => {
                      changeCurrentStudent(student.student_user_id);
                    }
                  "
                >
                  {{ user.displayName(student.student_user.first_name, student.student_user.last_name) }}
                </el-menu-item>
              </el-menu>
            </el-col>
            <el-col :span="28">
              <div v-if="!isCounseling && currentStudent.has_vocab_test">
                <el-input
                  disabled
                  :value="currentStudentScore"
                  style="width: 200px"
                >
                  <template slot="prepend">Vocab score</template> </el-input
                >/{{ currentStudentTotalScore }}
              </div>
              <div v-html="currentStudent.note_to_student" />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row
        v-if="isRoleAdmin() || (isRoleTeacher() && isTeacherInClass)"
        :gutter="20"
        class="rowGap"
        type="flex"
        justify="center"
      >
        <el-col :span="6">
          <div class="alignRight">
            <el-tooltip
              class="item"
              :content="
                isRoleAdmin()
                  ? 'Only admin and the teacher can see this.'
                  : (isRoleTeacher() && isTeacherInClass)
                  ? 'Only you can see this.'
                  : ''
              "
              placement="top"
              popper-class="tooltipColor"
            >
              <i class="el-icon-lock" />
            </el-tooltip>
            Notes To Self:
          </div>
        </el-col>
        <el-col :span="18">
          <div v-html="teachingLog.note_to_teacher" />
        </el-col>
      </el-row>
      <el-row :gutter="20" class="rowGap" type="flex" justify="center">
        <el-col :span="6">
          <div class="alignRight">{{ `Next ${logType}:` }}</div>
        </el-col>
        <el-col :span="18">
          {{
            teachingLog.next_lesson_at === "INVALID DATE"
              ? "Not scheduled"
              : `${teachingLog.next_lesson_at} (Taipei time)`
          }}
        </el-col>
      </el-row>
      <el-row
        v-if="(isRoleAdmin() || (isRoleTeacher() && isTeacherInClass)) && isPrivateLesson"
        :gutter="20"
        class="rowGap"
        type="flex"
        justify="center"
      >
        <el-col :span="6">
          <div class="alignRight">Salary:</div>
        </el-col>
        <el-col :span="18">
          {{
            `${addCurrencySymbol(
              Number(teachingLog.salary),
              teachingLog.salary_currency
            )} x ${teachingLog.full_hour} hr = ${addCurrencySymbol(
              Number(teachingLog.salary) * teachingLog.full_hour,
              teachingLog.salary_currency
            )}`
          }}
          <div>
            {{ `(Total salary = ${addCurrencySymbol(totalSalary(), "TWD")})` }}
          </div>
        </el-col>
      </el-row>
      <el-row
        v-if="(isRoleAdmin() || isRoleParent()) && isPrivateLesson"
        :gutter="20"
        class="rowGap"
        type="flex"
        justify="center"
      >
        <el-col :span="6">
          <div class="alignRight">Tuition:</div>
        </el-col>
        <el-col :span="18">
          <div :key="student.id" v-for="student in students">
            <span>
              {{
                `${user.displayName(student.student_user.first_name, student.student_user.last_name)}: `
              }}
            </span>
            {{
              `${addCurrencySymbol(
                Number(student.fee),
                student.fee_currency
              )} x ${teachingLog.full_hour} hr = ${addCurrencySymbol(
                Number(student.fee) * teachingLog.full_hour,
                student.fee_currency
              )}`
            }}
          </div>
          <div>
            {{
              `(Total tuition = ${addCurrencySymbol(totalTuition(), "TWD")})`
            }}
          </div>
        </el-col>
      </el-row>
      <el-row
        v-if="isRoleAdmin() && isTeachingLog && isPrivateLesson"
        :gutter="20"
        class="rowGap"
        type="flex"
        justify="center"
      >
        <el-col :span="6">
          <div class="alignRight">Profit:</div>
        </el-col>
        <el-col :span="18">
          {{
            `${addCurrencySymbol(totalTuition(), "TWD")} - ${addCurrencySymbol(
              totalSalary(),
              "TWD"
            )} = ${addCurrencySymbol(totalTuition() - totalSalary(), "TWD")}`
          }}
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import { user } from "@ivy-way/material";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import privateApi from "@/apis/private";
import lessonLogApi from "@/apis/lessonLog";
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import AttendanceStatusLight from "@/components/privateLesson/AttendanceStatusLight";
import { EnumAttendanceStatuses } from "@/enums";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.lessonLog")} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    AttendanceStatusLight
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    isTeacherInClass() {
      let isTeacherInClass = false;
      if (this.sessionClass && this.sessionClass.session_class_teachers) {
        console.log(this.sessionClass.session_class_teachers);
        this.sessionClass.session_class_teachers.forEach(teacher => {
          if (teacher.teacher_user_id === this.getProfile.id) {
            isTeacherInClass = true;
          }
        });
      }
      return isTeacherInClass;
    },
    user() {
      return user;
    },
    EnumAttendanceStatuses() {
      return EnumAttendanceStatuses;
    },
    routePath() {
      if (this.isPrivateLesson) {
        return "private-lessons";
      } else if (this.isCounseling) {
        return "counseling";
      }
      return "session-class";
    },
    lessonTitle() {
      return this.sessionClass[this.isPrivateLesson ? "subject" : "title"];
    },
    logType() {
      return this.isCounseling ? "Meeting" : "Lesson";
    },
    isPrivateLesson() {
      return this.$route.params.privateType === "private-lessons";
    },
    isCounseling() {
      return this.$route.params.privateType === "counseling";
    },
    isTeachingLog() {
      return this.$route.name.indexOf("TeachingLogDetail") !== -1;
    },
    isLessonLog() {
      return this.$route.name.indexOf("LessonLogDetail") !== -1;
    },
    currentStudent() {
      return this.lessonLogs.find(
        lessonLog => lessonLog.student_user_id === this.currentStudentId
      );
    },
    currentStudentTotalScore() {
      return this.currentStudent.total_score;
    },
    currentStudentScore() {
      return this.currentStudent.score;
    }
  },
  mixins: [moneyMixin, roleMixin],
  data() {
    return {
      students: [],
      lessonLogs: [],
      teachingLog: {},
      currentStudentId: null,
      sessionClass: null,
      nextTeachingLog: {}
    };
  },
  async created() {
    await this.initialExchangeRate();
    if(this.$route.params.courseId){
      const { session_class } = await privateApi.getCustomLesson({
        class_id: this.$route.params.courseId
      });
      this.sessionClass = session_class;
    }

    const { lesson_logs, teaching_log } = await this.fetchCourseLog();
    this.students = this.pickUpStudents(lesson_logs);
    this.currentStudentId = this.students[0].student_user_id;
    this.lessonLogs = lesson_logs;
    this.teachingLog = teaching_log;
    this.teachingLog.started_at = moment(this.teachingLog.started_at)
      .format("YYYY-MM-DD HH:mm a")
      .toUpperCase();
    this.teachingLog.next_lesson_at = moment(this.teachingLog.started_at)
      .format("YYYY-MM-DD HH:mm a")
      .toUpperCase();
    await this.fetchNextTeachingLog();
  },
  methods: {
    filterStudentByAttendance(students, attendanceStatus) {
      return students.filter(
        ({ attendance_status }) => attendance_status === attendanceStatus
      );
    },
    async fetchCourseLog() {
      if (this.isTeachingLog) {
        return await lessonLogApi.fetchTeachingLog(
          this.$route.params.teachingLogId
        );
      } else if (this.isLessonLog) {
        return await lessonLogApi.fetchLessonLog(
          this.$route.params.lessonLogId
        );
      }
    },
    changeCurrentStudent(studentId) {
      this.currentStudentId = studentId;
    },
    pickUpStudents(lessonLogs) {
      if (this.isTeachingLog) {
        return lessonLogs;
      } else if (this.isLessonLog) {
        return lessonLogs.filter(
          lessonLog =>
            lessonLog.student_user_id === Number(this.$route.params.studentId)
        );
      }
    },
    totalSalary() {
      const theTwdSalary = this.convertPriceByCurrency(
        this.teachingLog.salary * this.teachingLog.full_hour,
        this.teachingLog.salary_currency,
        "TWD"
      );
      return theTwdSalary;
    },
    totalTuition() {
      const theTwdTuitions = this.students.map(student =>
        this.convertPriceByCurrency(
          student.fee * this.teachingLog.full_hour,
          student.fee_currency,
          "TWD"
        )
      );
      return theTwdTuitions.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
    },
    getAttendanceStatusByUserId(userId) {
      return this.lessonLogs.find(
        ({ student_user_id }) => student_user_id === userId
      ).attendance_status;
    },
    getAbsenceExcuseByUserId(userId) {
      return this.lessonLogs.find(
        ({ student_user_id }) => student_user_id === userId
      ).absence_excuse;
    }
  }
};
</script>

<style lang="scss" scoped>
.lessonCard {
  padding: 20px;
  width: 970px;
  margin: 40px auto;
  border: 1px solid #444;
  .rowGap {
    margin: 10px 0px;
  }
  .alignRight {
    text-align: right;
  }
}

.attendanceStatusLight {
  margin-right: 8px;
}

.absenceExcuse {
  color: #6e90be;
}
</style>
