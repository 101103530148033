<template>
  <span :class="lightClassName">
    <i class="fas fa-circle markIcon" />
  </span>
</template>

<script>
import { EnumAttendanceStatuses } from "@/enums";

export default {
  props: {
    attendanceStatus: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      isOnline: 1
    };
  },
  computed: {
    lightClassName() {
      switch (this.attendanceStatus) {
        case EnumAttendanceStatuses.Present:
          return "markPresent";
        case EnumAttendanceStatuses.Late:
          return "markLate";
        case EnumAttendanceStatuses.Absent:
          return "markAbsent";
        case EnumAttendanceStatuses.Excused:
          return "markExcused";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.markPresent {
  .markIcon {
    color: #6ee17e;
    border-radius: 20px;
    box-shadow: 0 0 10px #25d83d;
    animation: breath 3s;
    animation-iteration-count: infinite;
  }
}
.markLate {
  .markIcon {
    color: #fcda41;
    border-radius: 20px;
    box-shadow: 0 0 10px #ffd311;
    animation: breath 3s;
    animation-iteration-count: infinite;
  }
}
.markAbsent {
  .markIcon {
    color: #ff5a2c;
    border-radius: 20px;
    box-shadow: 0 0 10px #fe683f;
    animation: breath 3s;
    animation-iteration-count: infinite;
  }
}
.markExcused {
  .markIcon {
    color: #B5DEFF;
    border-radius: 20px;
    box-shadow: 0 0 10px #6bbdff;
    animation: breath 3s;
    animation-iteration-count: infinite;
  }
}
@keyframes breath {
  0%    {opacity: 0.5;}
  60%   {opacity:   1;}
  100%  {opacity: 0.5;}
}
</style>
